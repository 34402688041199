import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImage";
import About from "components/hero/TwoColumnWithVideo";
import Features from "components/features/Benefits";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Services from "components/pricing/ThreePlans";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import FAQ from "components/faqs/SingleCol.js";
import Form from "components/forms/TwoColContactUsWithIllustrationFullForm";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnDark.js";
import { BannerBrands } from "components/bannerBrands";
import BrandsSlider from "components/BrandsSlider";
import MainLandingPage from "MainLandingPage";
import OurTeam from "components/team/OurTeam";
import Recipe from "components/features/Recipe";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} heading={'Simplify operations, boost online presence, and drive growth with confidence'} title={'Welcome to MyDispatch'} />     
      <GetStarted />
      <Services />
      <About />
      <Recipe />
      <OurTeam />
      <BrandsSlider />
      <FAQ/>
      <Form/>
      <Footer />
    </AnimationRevealPage>
  );
}
