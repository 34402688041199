import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/ContactHero";
import Form from "components/forms/TwoColContactUsWithIllustrationFullForm";
import Footer from "components/footers/FiveColumnDark.js";

export default () => {
  return (
    <AnimationRevealPage>
      <Hero heading={'Get in Touch'} />
      <Form/>
      <Footer />
    </AnimationRevealPage>
  );
}
