import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImage";
import Brands from "components/brands/Brands";
import Footer from "components/footers/FiveColumnDark.js";
import Form from "components/forms/TwoColContactUsWithIllustrationFullForm";
import FAQ from "components/faqs/SingleCol.js";
import MainLandingPage from "MainLandingPage";
import Features from "components/features/Benefits";
import CodeSolid from "images/code-solid (1).svg"
import RocketSolid from "images/rocket-solid.svg"
import Glass from "images/magnifying-glass-solid.svg"
import Palette from "images/palette-solid.svg"
import Users from "images/users-solid.svg"
import GetStarted from "components/cta/GetStarted";

export default () => {

  return (
    <AnimationRevealPage>
      <Hero heading={'Level up your online presence with our web and marketing expertise'} title={'Welcome to Web & Marketing section'} backgroundImage={'https://images.unsplash.com/photo-1467232004584-a241de8bcf5d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1169&q=80'} />
      <Brands />
      <GetStarted/>
      <Features cards={[
        {
          imageSrc: CodeSolid,
          title: "FullStack Website",
          description: "We create impactful websites that engage your audience, reflecting your brand identity and driving user conversions."
        },
        {
          imageSrc: RocketSolid, 
          title: "Landing Pages",
          description: "Our visually stunning landing pages captivate visitors, conveying your value proposition and increasing conversions."
        },
        {
          imageSrc: Glass, 
          title: "SEO Content",
          description: "Optimize your online content to enhance search engine visibility, attract organic traffic, and boost rankings."
        },
        {
          imageSrc: Palette, 
          title: "Graphic Design",
          description: "We design captivating logos, business cards, and marketing materials that establish a visually appealing brand identity."
        },
        {
          imageSrc: Users, 
          title: "Social Media Pages",
          description: "Expand your online presence through strategic management of social media platforms, building brand awareness and loyalty."
        },
      ]} />
      <MainLandingPage />
      <FAQ faqs={[
        {
          question: "Where can I get a professional website for my business?",
          answer:
            "Our web design team creates professional websites tailored to your business needs. We ensure that your website is visually appealing, user-friendly, and optimized for search engines."
        },
        {
          question: "Can you help me with search engine optimization (SEO)?",
          answer:
            "Yes, we provide SEO services to help improve your website's visibility in search engine results. We optimize your website's content, meta tags, and other factors to rank higher and attract organic traffic."
        },
        {
          question: "What social media platforms do you work with?",
          answer:
            "We work with popular social media platforms like Facebook, Instagram, Twitter, LinkedIn, and more. We can create and manage your business profiles, run targeted ad campaigns, and engage with your audience."
        },
        {
          question: "How can you improve my online presence?",
          answer:
            "We employ various strategies to enhance your online presence. This includes creating engaging content, managing your online listings, building backlinks, and implementing online advertising campaigns."
        },
        {
          question: "Do you offer email marketing services?",
          answer:
            "Yes, we provide email marketing services to help you reach and engage with your target audience. We design and send professional email campaigns, track their performance, and optimize for better results."
        },
        {
          question: "Can you help with website analytics and tracking?",
          answer:
            "Absolutely! We integrate website analytics tools like Google Analytics to track and analyze your website's performance. We provide reports on traffic, user behavior, conversions, and other key metrics."
        }
      ]} />
      <Footer />
    </AnimationRevealPage>
  );
}
