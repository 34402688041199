import React from 'react';
import Slider from 'react-slick';

import './TeamCarousel.css'; // Archivo de estilos
import Ale from 'images/team/Ale.webp'
import Dante from 'images/team/Dante.webp'
import Lao from 'images/team/Lao.webp'
import Luca from 'images/team/Luca.webp'
import Lucho from 'images/team/Lucho.webp'
import Marce from 'images/team/Marce.webp'
import Berko from 'images/team/Berko.webp'
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import tw from "twin.macro";
import { ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionDescription } from "components/misc/Typography.js";

const Container = tw.div`relative pb-6`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl text-center`;
const Description = tw(SectionDescription)`w-full text-center`;
const HeaderContainer = tw.div`mt-10 mb-10 w-full flex flex-col items-center`;

const teamMembers = [
  {
    id: 1,
    name: 'Nir Bercovich',
    position: 'Owner & CO-CEO',
    image: Berko,
  },
  {
    id: 2,
    name: 'Marcelo Sznek',
    position: 'CO-CEO',
    image: Marce,
  },
  {
    id: 3,
    name: 'Dante Briceño',
    position: 'Finance Manager',
    image: Dante,
  },
  {
    id: 3,
    name: 'Alejandro Toledo',
    position: 'IT Manager',
    image: Ale,
  },
  {
    id: 3,
    name: 'Lucio Cassin',
    position: 'Dispatcher',
    image: Lucho,
  },
  {
    id: 3,
    name: 'Estanislao Campos',
    position: 'Dispatcher',
    image: Lao,
  },
  {
    id: 3,
    name: 'Luca Lo Presti',
    position: 'Dispatcher',
    image: Luca,
  },
  // Add more team members as needed
];

const OurTeam = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container>
      <div className='teamSlider text-center'>
        <div className="other-text">
          <HeaderContainer>
            <Subheading>Meet Our Team</Subheading>
            <Heading>MyDispatch Team</Heading>
            <Description>We have assembled a team of dedicated professionals who bring a wealth of experience and expertise to every project we undertake.</Description>
          </HeaderContainer>
        </div>
      </div>
      <Slider {...settings}>
        {teamMembers.map((member) => (
          <div key={member.id} className="team-card">
            <img
              className="team-card-image"
              src={member.image}
              alt={member.name}
            />
            <h3 className="team-card-name">{member.name}</h3>
            <p className="team-card-position">{member.position}</p>
          </div>
        ))}
      </Slider>

    </Container>
  );
};

export default OurTeam;