import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl text-center`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;



export default ({
  subheading = "FAQS",
  heading = "Have Any Questions?",
  description = "Please read through our FAQs below or contact us for customized solutions.",
  faqs = [
    {
      question: "How can MyDispatch assist with inventory management for mobile service businesses?",
      answer: "At MyDispatch, we have a streamlined approach to inventory management, ensuring that your mobile service business has the necessary supplies and equipment readily available when needed. Our efficient tracking and reporting systems help optimize inventory levels and reduce stockouts."
    },
    {
      question: "What fleet management solutions does MyDispatch offer?",
      answer: "Our fleet management solutions encompass live monitoring, maintenance scheduling, and performance tracking for your mobile service vehicles. We provide real-time insights, enabling you to enhance fleet efficiency, track vehicle locations, and improve overall productivity."
    },
    {
      question: "How does MyDispatch handle weekly tech and company reports?",
      answer: "We provide detailed and insightful weekly reports that encompass technician performance, service metrics, and overall business operations. Our reports help you gain valuable insights, identify areas for improvement, and make informed decisions to enhance your mobile service business."
    },
    {
      question: "Can MyDispatch handle call answering services for my mobile service business?",
      answer: "Absolutely! Our dedicated team ensures prompt and professional handling of customer calls. We offer call answering services, including inquiries, appointment scheduling, and customer support. By entrusting us with this critical task, you can focus on delivering exceptional service to your customers."
    },
    {
      question: "How can MyDispatch help with dispatcher services?",
      answer: "Our skilled dispatchers specialize in efficiently coordinating your mobile service operations. We excel in assigning jobs to technicians, optimizing routes, and managing service requests. Our dispatcher services streamline operations, ensuring smooth workflow and maximizing productivity."
    },
    {
      question: "Does MyDispatch support software management for mobile service businesses?",
      answer: "Yes, we are well-versed in managing various software platforms commonly used by mobile service businesses, such as Workiz, UleadZ, PowerDispatch, Jobox, and more. Our expertise ensures seamless integration and efficient software management, enhancing overall operational effectiveness."
    }
  ]

}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
