import React from "react";
import styled from "styled-components"; //eslint-disable-line
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts";

const PrimaryBackgroundContainer = tw.div`py-12 lg:py-16 bg-gray-200 relative`
const Row = tw.div`px-4 sm:px-8 max-w-screen-xl mx-auto flex items-center relative z-10 flex-col text-center`;

const ColumnContainer = tw.div`lg:w-full`
const TextContainer = tw(ColumnContainer)`mb-4`;
const Text = tw.h5`text-gray-800 text-xl sm:text-3xl font-black text-center`;

const LinksContainer = tw(ColumnContainer)`flex justify-center mt-6 flex-col sm:flex-row`;

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-6 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`shadow text-gray-100 hocus:text-gray-300 bg-primary-500 hocus:bg-primary-900`;

const SecondaryLink = tw(Link)`shadow text-gray-100 hocus:text-gray-300 bg-gray-600 hocus:bg-gray-900`;
const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`
const DecoratorBlob1 = tw(SvgDecoratorBlob1)`hidden sm:hidden md:block md:absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-primary-700 opacity-50`;
const DecoratorBlob2 = tw(SvgDecoratorBlob1)`hidden sm:hidden md:block md:absolute top-0 right-0 w-80 h-80 transform translate-x-20 -translate-y-64 text-primary-700 opacity-50`;

export default ({
  text = "Time is your most valuable asset, maximize it with MyDispatch! Delegate the administrative tasks and seize strategic opportunities.",
  primaryLinkText = "Get Started",
  primaryLinkUrl = "/contact",
  secondaryLinkText = "Contact Us",
  secondaryLinkUrl = "/contact",
  pushDownFooter = true
}) => {
  return (
    <Container css={pushDownFooter && tw``}>
      <PrimaryBackgroundContainer>
        <Row>
          <TextContainer>
            <Text>{text}</Text>
          </TextContainer>
          <LinksContainer>
            <PrimaryLink href={primaryLinkUrl}>{primaryLinkText}</PrimaryLink>
            <SecondaryLink href={secondaryLinkUrl}>{secondaryLinkText}</SecondaryLink>
          </LinksContainer>
        </Row>
        <DecoratorBlobContainer>
          <DecoratorBlob1/>
          <DecoratorBlob2/>
        </DecoratorBlobContainer>
      </PrimaryBackgroundContainer>
    </Container>
  );
};
