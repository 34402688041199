import React from 'react';
import brand2 from 'images/bookkeeping/logo-facebook.png';
import brand3 from 'images/bookkeeping/logo-google.png';
import brand6 from 'images/bookkeeping/logo-yelp.png';
import tw from "twin.macro";

const Brands = () => {
  const images = [
    brand2,
    brand3,
    brand6,
  ];

  const PlanAction = tw.div`flex flex-wrap justify-center p-4 md:mx-24 md:py-8`;
  const ContainerImage = tw.div`w-1/3 md:w-1/6 mx-auto p-2 text-center`;
  const Image = tw.img`m-auto`; // Agregado "m-auto" para aplicar el margen automático
  return (
    <PlanAction>
      {images.map((image, index) => (
        <ContainerImage key={index}>
          <Image src={image} alt={`Foto ${index + 1}`} />
        </ContainerImage>
      ))}
    </PlanAction>
  );
};

export default Brands;
