import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImage";
import Brands from "components/brands/Brands";
import Footer from "components/footers/FiveColumnDark.js";
import Form from "components/forms/TwoColContactUsWithIllustrationFullForm";
import FAQ from "components/faqs/SingleCol.js";
import Box from "images/box-archive-solid.svg"
import Truck from "images/truck-solid.svg"
import Files from "images/file-lines-solid.svg"
import Phone from "images/phone-solid.svg"
import Headset from "images/headset-solid.svg"
import Database from "images/database-solid.svg"
import Callcenter from "images/callcenter.jpg"
import Features from "components/features/Benefits";
import GetStarted from "components/cta/GetStarted";
import Features2 from "components/features/VerticalWithAlternateImageAndText.js";

export default () => {

  return (
    <AnimationRevealPage>
      <Hero heading={'Efficient Dispatch and Management Solutions for Your Business'} title={'Welcome to Dispatch & Management section'} backgroundImage={'https://images.pexels.com/photos/5453909/pexels-photo-5453909.jpeg'} />
      <GetStarted/>
      <Features2 cards={[
        {
          imageSrc: 'https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1147&q=80',
          subtitle: "Streamlining Business Operations:",
          title: "The Benefits of Outsourcing Office Tasks for Mobile Service Providers",
          description:
            "MyDispatch initially provided dispatch and management solutions to a local mobile locksmith company in Chicago, IL, owned by CEO Nir Berkovich and managed by CO-CEO Marcelo Sznek. However, the COVID-19 pandemic led to a surge in job requests, overwhelming our team of technicians. To cope with the increased demand, we developed efficient protocols, reports, and applications. As our office operations became more streamlined, we closed more jobs successfully. Today, MyDispatch is a trusted partner for mobile service companies, offering comprehensive solutions for efficient operations",
          url: "https://timerse.com"
        },
      ]} />
      <Features cards={[
        {
          imageSrc: Box,
          title: "Inventories",
          description: "Efficiently manage supplies, ensuring availability of necessary equipment for your mobile service business."
        },
        {
          imageSrc: Truck,
          title: "Fleet maintenance & live monitoring",
          description: "Real-time monitoring, scheduling, and tracking optimize efficiency and reliability of mobile service vehicles."
        },
        {
          imageSrc: Files,
          title: "Weekly tech and companies reports",
          description: "Detailed reports on technician performance, metrics, and operations for informed decision-making."
        },
        {
          imageSrc: Phone,
          title: "Call answering services",
          description: "Prompt and professional handling of customer calls, inquiries, scheduling, and support."
        },
        {
          imageSrc: Headset,
          title: "Dispatcher services",
          description: "Skilled dispatchers streamline coordination, maximize productivity, and ensure customer satisfaction in mobile service operations."
        },
        {
          imageSrc: Database,
          title: "CRM management",
          description: "Proficiency in software platforms enables effective management, seamless integration, and workflow efficiency for mobile service businesses."
        }
      ]
      } />
      <FAQ />
      <Features2 cards={[
        {
          imageSrc: Callcenter,
          subtitle: "The best solution",
          title: "Why choose us?",
          description:
            "MyDispatch initially provided dispatch and management solutions to a local mobile locksmith company in Chicago, IL, owned by CEO Nir Berkovich and managed by CO-CEO Marcelo Sznek. However, the COVID-19 pandemic led to a surge in job requests, overwhelming our team of technicians. To cope with the increased demand, we developed efficient protocols, reports, and applications. As our office operations became more streamlined, we closed more jobs successfully. Today, MyDispatch is a trusted partner for mobile service companies, offering comprehensive solutions for efficient operations",
          url: "https://timerse.com"
        },
      ]} />
      <Footer />
    </AnimationRevealPage>
  );
}
