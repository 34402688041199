import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useState } from 'react'
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import emailjs from 'emailjs-com'


const Container = tw.div`relative px-12`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-4 md:py-8`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12  md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);
const initialState = {
  name: '',
  email: '',
  message: '',
  phone: '',
}

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col w-full mx-auto md:mx-0`
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr/> with us.</>,
  description = "If you have any questions about our services or pricing, or wish to learn more about how we can serve you directly, please fill out our convenient enquiry form. Leave your details and we will contact you immediately!",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const [{ name, email, business, calls, phone}, setState] = useState(initialState)
  const [success, setSucces] = useState(false)
  console.log(name)
  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, business, calls, phone)
    emailjs
      .sendForm(
        'service_7vd21ij', 'template_dx7rwrw', e.target, 'g7SQAEAswwuRYz-dx'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
          setSucces(true)
        },
        (error) => {
          console.log(error.text)
        }
      )
  }

  return (
    <Container id="contact">
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            
          </TextContent> 
        </TextColumn>
        <ImageColumn>
          <Form action={formAction} method={formMethod} onSubmit={handleSubmit}>
            <Input type="text" name="name" placeholder="Your Full Name" onChange={handleChange}/>
            <Input type="text" name="business" placeholder="Name of the Business" onChange={handleChange}/>
            <Input type="email" name="email" placeholder="Your Business Email" onChange={handleChange}/>
            <Input type="text" name="phone" placeholder="Your Phone Number" onChange={handleChange}/>
            <Input type="text" name="calls" placeholder="Service are you interested" onChange={handleChange}/>
            <SubmitButton type="submit">{submitButtonText}</SubmitButton>
          </Form>
        </ImageColumn>
      </TwoColumn>
    </Container>
  );
};
