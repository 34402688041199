import React from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import angi from "images/brands/angi.png";
import chamberlain from "images/brands/chamberlain.png";
import genie from "images/brands/genie.png";
import google from "images/brands/google.png";
import google2 from "images/brands/google2.png";
import yelp from "images/brands/yelp.png";
import liftmaster from "images/brands/liftmaster.png";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";

const HighlightedText = tw.span`text-primary-500`;
const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug text-center`;

function BrandsSlider() {
  var brands = ['angi', 'chamberlain','genie','google', 'google2']
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        responsive: [
          {
            breakpoint: 983,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 820,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              rows: 2,
              slidesPerRow: 1
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              rows: 2,
              slidesPerRow: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              rows: 1,
              slidesPerRow: 2
            }
          }
        ]
      };
    
    return (
      <Container>
      <div id='brandsSlider' className='brandsSlider text-center'>
        <div className="other-text">
          <Heading>Some of our <HighlightedText>Clients.</HighlightedText></Heading>
        </div>
        <Slider {...settings}>
          <img src={angi} alt={`${angi}-logo`}/>
          <img src={chamberlain} alt={`${chamberlain}-logo`}/>
          <img src={genie} alt={`${genie}-logo`}/>
          <img src={google} alt={`${google}-logo`}/>
          <img src={google2} alt={`${google2}-logo`}/>
        </Slider>
      </div>
      </Container>
    );
}

export default BrandsSlider;