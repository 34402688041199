import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImage";
import About from "components/hero/TwoColumnWithVideo";
import Pricing2 from "components/pricing/PricingBookkeeping";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import FAQ from "components/faqs/SingleCol.js";
import Form from "components/forms/TwoColContactUsWithIllustrationFullForm";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnDark.js";
import Brands from "components/brands/Brands";
import Bookkeeping from "images/bookkeeping/bookkeeping.avif";
import Features2 from "components/features/VerticalWithAlternateImageAndText.js";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero heading={'Relax while we handle your bookkeeping needs with expertise and efficiency'} title={'Welcome to Bookkeeping section'} backgroundImage={'https://dimovtax.com/wp-content/uploads/2019/07/AdobeStock_102558088-e1579149866669.jpeg'} />
      <Brands />
      <GetStarted />
      <Features2
        cards={[
          {
            reversed: true,
            imageSrc: Bookkeeping,
            subtitle: "Bookkeeping",
            title: "Advantages of implementing Bookkeeping into your business",
            description:
              "Prioritizing the significance of bookkeeping and accounting is a critical factor in the success of any business. Maintaining updated transaction records is crucial for a precise evaluation of business performance. Accurate financial reports based on these records can offer valuable insights to help make informed decisions.\n        On the other hand, outsourcing your bookkeeping is also a smart move that can save you time, resources, and money on additional accountant charges. Improperly maintained books can result in higher fees from accountants, but with MyDispatch, you can be confident that your books will be accurate from the start.",
            url: "https://timerse.com"
          },
        ]}
      ></Features2>
      <Pricing2 />
      <FAQ faqs={[
        {
          question: "What bookkeeping services do you offer?",
          answer:
            "We offer comprehensive bookkeeping services, including accounts payable, accounts receivable, general ledger maintenance, bank reconciliation, financial statement preparation, and more."
        },
        {
          question: "Can you handle my payroll processing?",
          answer:
            "Yes, we provide payroll processing services, which include calculating employee wages, generating pay stubs, handling tax withholdings, and ensuring timely and accurate payroll disbursements."
        },
        {
          question: "Do you assist with tax preparation?",
          answer:
            "Absolutely! We can assist with tax preparation by organizing your financial records, ensuring compliance with tax regulations, and preparing and filing your business tax returns."
        },
        {
          question: "How do you ensure data security and confidentiality?",
          answer:
            "We take data security and confidentiality seriously. We use secure and encrypted systems to store and transfer financial data. Additionally, our staff follows strict privacy protocols and confidentiality agreements."
        },
        {
          question: "Can you help with budgeting and financial planning?",
          answer:
            "Yes, we can assist with budgeting and financial planning. We analyze your financial data, identify trends, and provide insights to help you make informed decisions and achieve your financial goals."
        },
        {
          question: "What software or tools do you use for bookkeeping?",
          answer:
            "We utilize industry-standard bookkeeping software like QuickBooks, Xero, or Wave to manage your financial records. We can also work with your existing software if preferred."
        }
      ]} />

      <Features2 cards={[
        {
          imageSrc: 'https://images.unsplash.com/photo-1563986768711-b3bde3dc821e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1168&q=80',
          subtitle: "The best solution",
          title: "Why choose us?",
          description:
            "Our team comprises expert bookkeepers and committed support staff who are dedicated to offering unparalleled professional assistance to your business. We pride ourselves on providing exceptional service that guarantees your business receives the highest level of support. As a united and approachable team, we recognize that our primary responsibility is to simplify the complexities of bookkeeping for you. We will collaborate closely with you to provide an accounting service that propels your business forward year after year.",
          url: "https://timerse.com"
        },
      ]} />
      <Footer />
    </AnimationRevealPage>
  );
}
