import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import SmoothScroll from "smooth-scroll";
import HomePage from "page/HomePage.js";
import BookkeepingPage from "page/BookkeepingPage.js";
import ContactPage from "page/ContactPage.js";
import { ThemeProvider } from 'styled-components';
import ChatBot from 'react-simple-chatbot';
import logo from 'images/logo.svg'


// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../src/styles/styles.css"

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WebMarketingPage from "page/WebMarketingPage";
import DispatchPage from "page/DispatchPage";
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});
export default function App() {

  const steps = [
    {
      id: '0',
      message: 'Hello! Welcome to our chatbot. Before we get started, may I please ask for your name?',

      // This calls the next id
      // i.e. id 1 in this case
      trigger: '2',
    }, {
      id: '2',

      // Here we want the user
      // to enter input
      user: true,
      trigger: '3',
    }, {
      id: '3',
      message: "Great to meet you, {previousValue}! How can I assist you today?",
      trigger: 4
    }, {
      id: '4',
      options: [

        // When we need to show a number of
        // options to choose we create alist
        // like this
        { value: 1, label: 'Dispatch' },
        { value: 2, label: 'Bookkeeping' },
        { value: 2, label: 'Web & Marketing' },

      ],
      end: true
    }
  ];
  // Creating our own theme
  const theme = {
    background: 'whitesmoke',
    headerBgColor: 'rgb(0, 155 ,255)',
    headerFontSize: '20px',
    botBubbleColor: 'rgb(0, 155 ,255)',
    headerFontColor: 'white',
    botFontColor: 'white',
    userBubbleColor: 'rgb(0, 155 ,255)',
    userFontColor: 'white',
  };

  // Set some properties of the bot
  const config = {
    botAvatar: logo,
    floating: true,
  };
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <ChatBot

          // This appears as the header
          // text for the chat bot
          headerTitle="MyDispatch"
          steps={steps}
          {...config}

        />
      </ThemeProvider>
      <Router>
        <Routes>
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/dispatch" element={<DispatchPage />} />
          <Route path="/web-marketing" element={<WebMarketingPage />} />
          <Route path="/bookkeeping" element={<BookkeepingPage />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </Router>
    </>
  );
}

