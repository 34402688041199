import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container as ContainerBase, ContentWithPaddingXl as ContentBase } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as CheckboxIcon } from "images/checkbox-circle.svg";
import TruckIcon from "images/truck-solid.svg";
import BookIcon from "images/book-solid.svg";
import GlobeIcon from "images/globe-solid.svg";

const Container = tw(ContainerBase)`px-2`;
const ContentWithPaddingXl = tw(ContentBase)`max-w-screen-xl mx-auto py-4 md:py-16`;
const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl text-center mb-4`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlansContainer = tw.div`flex justify-between flex-col lg:flex-row items-center lg:items-stretch relative`;
const Plan = styled.div`
  ${tw`w-full max-w-sm mb-8 lg:mb-0 lg:mt-16 lg:mr-8 lg:last:mr-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }

  ${props =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
      background: rgb(85,60,154);
      background: linear-gradient(135deg, rgba(85,60,154,1) 0%, rgba(128,90,213,1) 100%);
      background: rgb(76,81,191);
      background: linear-gradient(135deg, rgba(76,81,191,1) 0%, rgba(102,126,234,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`text-gray-300!`}
      }
      ${BuyNowButton} {
        ${tw`bg-gray-100 text-primary-500 hocus:bg-gray-300 hocus:text-primary-800`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed pt-8`}
  .name {
    ${tw`font-bold text-xl py-2`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
`;
const PlanFeatures = styled.ul`
  ${tw`flex-1 lg:-mx-6 -mx-6 sm:-mx-10 py-2 px-6 sm:px-10 lg:px-6 lg:pb-6 xl:-mx-10 xl:px-10 xl:pb-10`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 text-gray-600 flex-shrink-0 mr-2`}
    }
    .iconGrey {
      ${tw`w-6 h-6 text-gray-600 flex-shrink-0 mr-2`}
    }
    .text {
      ${tw`font-semibold text-primary-900 tracking-wide text-left`}
    }
    .textGrey {
      ${tw`text-gray-600 tracking-wide text-left`}
    }
    .textLightGrey {
      ${tw`text-gray-400 tracking-wide text-left`}
    }
    .textBlack {
      ${tw`text-black tracking-wide text-left`}
    }
    .textWhite {
      ${tw`text-white tracking-wide text-left`}
    }
  }
`;

const PlanAction = tw.div`px-2 sm:px-4 xl:px-8 py-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const DecoratorBlob = styled(SvgDecoratorBlob)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-1/2 translate-y-1/2 `}
`;
const Card = styled.div`
  ${tw`flex flex-col items-center text-center h-full rounded transition-transform duration-300 transform`}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-primary-500 flex items-center justify-center`}
    img {
      ${tw`w-10 h-10`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300  leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

export default ({
  subheading = "Pricing",
  heading = "Our Services",
  description = "At MyDispatch, we recognize and understand the need for cost-effective business management solutions.",
  plans = null,
  primaryButtonText = "More Info"
}) => {
  const defaultPlans = [
    {
      name: "Dispatch and Managment",
      price: "$499",
      mainFeature: "Suited for Personal Blogs",
      icon: TruckIcon,
      linkUrl: "/dispatch",
      features: [
        "Inventories",
        "Fleet maintenance & live monitoring",
        "Weekly tech and companies reports",
        "Call answering services",
        "Dispatcher services",
        "CRM management",
      ],
    },
    {
      name: "Bookkeeping",
      price: "$799",
      mainFeature: "Suited for Production Websites",
      icon: BookIcon,
      linkUrl: "/bookkeeping",
      features: [
        "Organize Chart of Accounts",
        "Transaction Categorization",
        "Bank & CC Reconciliation",
        "Profit & Loss Reports",
        "Balance Sheet Report",
        "Maintaining AR/AP",
      ],
    },
    {
      name: "Web and marketing",
      price: "$1199",
      mainFeature: "Suited for Big Companies",
      icon: GlobeIcon,
      linkUrl: "/web-marketing",
      features: [
        "FullStack Website",
        "Landing Page",
        "SEO Content",
        "Graphic Design",
        "Branding",
        "Social Media Pages",
      ],
    },
  ];

  if (!plans) plans = defaultPlans;

  const highlightGradientsCss = [
    css`
      background: #00ABFF;
    `,
    css`
      background: #00ABFF;
    `,
    css`
      background: #00ABFF;
    `,
  ];

  return (
    <Container id="pricing">
      <ContentWithPaddingXl>
        <HeaderContainer>
          <Heading>{heading}</Heading>
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              {!plan.featured && <div className="planHighlight" css={highlightGradientsCss[index % highlightGradientsCss.length]} />}
              <PlanHeader>
                <Card>
                  <span className="imageContainer" >
                    <img src={plan.icon} alt="" />
                  </span>
                </Card>
                <span className="name">{plan.name}</span>
              </PlanHeader>
              <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <li className="feature" key={index}>
                    <CheckboxIcon className="icon" />
                    <span className={plan.featured ? 'textWhite' : 'textBlack'}>{feature}</span>
                  </li>
                ))}

              </PlanFeatures>
              <PlanAction>
                <BuyNowButton css={!plan.featured && highlightGradientsCss[index]}><a href={plan.linkUrl} >{primaryButtonText}</a></BuyNowButton>
              </PlanAction>
            </Plan>
          ))}
          <DecoratorBlob />
        </PlansContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
