import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import Bullseye from "../../images/bullseye-solid.svg";
import Glass from "../../images/magnifying-glass-plus-solid.svg";
import Stopwatch from "../../images/stopwatch-solid.svg";
import Trophy from "../../images/trophy-solid.svg";

const Container = tw.div`relative bg-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto pb-12`}
`;

const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl text-center mx-auto pt-12 pb-6`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/4 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col bg-white mx-auto max-w-xs items-center px-6 py-10 shadow-lg rounded-lg mt-4`}
  .imageContainer {
    ${tw`bg-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-gray-600`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: Bullseye,
      title: "Plan for Success",
      description: "Thoughtfully strategize to achieve desired outcomes and surpass goals."
    },
    {
      imageSrc: Glass,
      title: "Attention to details",
      description: "Meticulously focus on precision, ensuring nothing is overlooked or compromised."
    },
    {
      imageSrc: Trophy,
      title: "Award-winning",
      description: "Recognized and celebrated for outstanding achievements, setting a benchmark for excellence."
    },
    {
      imageSrc: Stopwatch,
      title: "Meeting Deadlines",
      description: "Consistently deliver on time, honoring commitments and achieving timely completion of tasks."
    },
  ];

  return (
    <Container>
      <Heading>Our Secret <span tw="text-primary-500 ">Recipe</span></Heading>
      <ThreeColumnContainer>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
